import cx from 'classnames'
import Link from 'next/link'
import { FC, Fragment, useContext } from 'react'
import { CartContext } from '../../../contexts/cart'
import { formatPrice } from '../../../lib/utils'
import Counter from '../../catalog/Product/Counter/Counter'
import Image from '../../common/Image/Image'
import styles from './CartList.module.scss'
import DeleteIcon from './delete.svg'

const CartList: FC<{
  closeHandler?: () => void
}> = ({ closeHandler }) => {
  const { cart, setQuantity, remove, loading, frameBasketItems } =
    useContext(CartContext)

  if (!cart?.items) {
    return null
  }

  const close = () => {
    if (closeHandler) {
      closeHandler()
    }
  }

  return (
    <ul className={styles.list}>
      {cart?.items.map((item, index) => {
        const frameItem = frameBasketItems?.filter((it) => it.id === item.id)[0]
        const priceItem = frameItem ? frameItem : item
        const image = item.element?.previewPicture?.url
        const probeParent = item?.element?.propertyValuesRelation?.find(
          (prop) => prop.property.code === 'PRODUCT',
        )
        const isJulirea =
          item.element?.parentSection?.parentSection?.code === 'julirea'
        const isKids =
          item.element?.parentSection?.code ===
            'detskaya_mineralnaya_kosmetika' ||
          item.element?.parentProduct?.parentSection?.code ===
            'detskaya_mineralnaya_kosmetika'

        const name =
          item?.element?.parentProduct &&
          item?.element?.parentProduct?.parentSection?.active
            ? item?.element?.parentProduct.name
            : item.name
        const description =
          item?.element?.productType === 'SIMPLE' ? null : item.element?.name
        let link = isJulirea ? '' : `/catalog/`

        if (isKids) {
          link += `detskaya_mineralnaya_kosmetika/${
            item.element?.parentProduct
              ? item.element?.parentProduct?.code
              : item.element?.code
          }${
            item.element?.parentProduct ? '?offer=' + item.element?.bx_id : ''
          }`
        } else if (
          probeParent ||
          item.element?.parentSection?.code === 'probes'
        ) {
          if (probeParent) {
            link += `${probeParent?.targetElement?.parentProduct?.parentSection?.parentSection?.code}/${probeParent.targetElement?.parentProduct?.parentSection?.code}/${probeParent.targetElement?.parentProduct?.code}?offer=${probeParent.targetElement?.bx_id}`
          }
        } else if (item.element?.parentProduct) {
          link += `${item.element?.parentProduct?.parentSection?.parentSection?.code}/${item.element?.parentProduct?.parentSection?.code}/${item.element?.parentProduct?.code}?offer=${item.element?.bx_id}`
        } else {
          link += isJulirea
            ? `julirea/${item.element?.code}`
            : `${item.element?.parentSection?.parentSection?.code}/${item.element?.parentSection?.code}/${item.element?.code}`
        }

        return (
          <li key={`${item.id}-${index}`} className={styles.item}>
            <div className={styles.image}>
              <Link href={link} passHref>
                <a onClick={close}>
                  <Image
                    src={image || '/static/images/product-no-image.jpg'}
                    objectFit="cover"
                    width={120}
                    height={120}
                    local={!image}
                  />
                </a>
              </Link>
            </div>
            <div className={styles.text}>
              <Link href={link} passHref>
                <a className={styles.name} onClick={close}>
                  {name}
                </a>
              </Link>
              {(description || item?.props?.length > 0) && (
                <p className={styles.description}>
                  {item?.props?.length > 0
                    ? item.props.map((p, index) => (
                        <Fragment key={`${p.name}-${index}`}>
                          <span key={index}>{p.value}</span>
                          <br />
                        </Fragment>
                      ))
                    : description}
                </p>
              )}

              <p className={cx(styles.price, loading && styles.loading)}>
                <span className={styles.realPrice}>
                  {formatPrice(
                    priceItem.basePriceTotal > priceItem.priceTotal
                      ? priceItem.priceTotal
                      : priceItem.basePriceTotal,
                  )}
                </span>
                {priceItem.basePriceTotal > priceItem.priceTotal && (
                  <span className={styles.discountPrice}>
                    {' ' + formatPrice(priceItem.basePriceTotal)}
                  </span>
                )}
              </p>
              <Counter
                small
                count={item.quantity}
                allowBelowOne
                setCount={(count) => {
                  if (count <= 0) {
                    remove(item.id)
                    return
                  }
                  setQuantity(item.id, count)
                }}
              />
            </div>
            <button
              className={styles.delete}
              type="button"
              onClick={() => {
                remove(item.id)
              }}
            >
              <DeleteIcon />
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default CartList
