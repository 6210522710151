import { FC } from 'react'
import styles from './Captcha.module.scss'
import cn from 'classnames'

const Captcha: FC<{ className?: string }> = ({ className }) => {
  return (
    <p className={cn(styles.text, className)}>
      Сайт использует reCAPTCHA согласно{' '}
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noreferrer"
      >
        Политике конфиденциальности
      </a>{' '}
      и{' '}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        Условиям обслуживания
      </a>{' '}
      Google.
    </p>
  )
}

export default Captcha
