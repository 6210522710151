import React, { FC, useContext } from 'react'

import FooterNavItem from './FooterNavItem/FooterNavItem'

import { footerNav } from '../../../data'
import { LayoutContext } from '../../../../contexts/layoutProvider'
import css from './FooterNav.module.scss'

const FooterNav: FC = () => {
  const { mainNav, julireaNav } = useContext(LayoutContext)

  const navs = [
    {
      code: 'julirea',
      title: 'уход Julirea',
      items: julireaNav?.map((item) => ({
        href: `/julirea#julirea-${item.code}`,
        ...item,
      })),
    },
    {
      code: 'catalog',
      title: 'Каталог',
      items: mainNav,
    },
    ...footerNav,
  ]

  return (
    <ul className={css.container}>
      {navs.map((nav) => {
        return <FooterNavItem key={nav.code} {...nav} />
      })}
    </ul>
  )
}

export default FooterNav
