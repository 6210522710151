import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from 'body-scroll-lock-upgrade'
import React, { FC, useEffect, useRef } from 'react'
import styles from './MobileNavModal.module.scss'
interface IModal {
  children?: React.ReactNode
  open: boolean
}

const MobileNavModal: FC<IModal> = ({ children, open }: IModal) => {
  const dialogRef = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (!dialogRef?.current) return

    const scrollPosition = window.scrollY

    if (open) {
      disableBodyScroll(dialogRef.current)
    } else {
      clearAllBodyScrollLocks()
      window.scrollTo(0, scrollPosition)
    }

    return () => {
      clearAllBodyScrollLocks()
      window.scrollTo(0, scrollPosition)
    }
  }, [open, dialogRef])

  return (
    <dialog className={styles.dialog} open={open} ref={dialogRef}>
      {children}
    </dialog>
  )
}

export default MobileNavModal
