import { FC, useContext } from 'react'
import Link from 'next/link'

import Button from '../../common/Button/Button'
import styles from './AsideBottom.module.scss'
import { CartContext } from '../../../contexts/cart'
import { formatPrice } from '../../../lib/utils'

interface AsideBottom {
  onClose: () => void
}
const AsideBottom: FC<AsideBottom> = ({ onClose }) => {
  const { cart, loading } = useContext(CartContext)

  return (
    <div className={styles.buttonContainer}>
      <Link href="/checkout" passHref>
        <Button className={styles.button} onClick={onClose}>
          к оформлению
          <span className={styles.dot} />
          <span className={loading && styles.loading}>
            {formatPrice(cart?.info?.price)}
          </span>
        </Button>
      </Link>
    </div>
  )
}

export default AsideBottom
