import { FC } from 'react'
import LoadingIcon from '../../../public/static/icons/form-loader.svg'
import styles from './Loader.module.scss'
import cn from 'classnames'

const Loader: FC<{ className?: string }> = ({ className }) => {
  return <LoadingIcon className={cn(styles.icon, className)} />
}

export default Loader
