export default function createContainer({
  id,
  className,
}: {
  id: string
  className?: string
}): HTMLElement {
  let element = document.getElementById(id)

  if (element) {
    return element
  }

  element = document.createElement('div')
  element.setAttribute('id', id)

  if (className) {
    element.className = className
  }

  document.body.appendChild(element)
  return element
}
