import React, { FC, useEffect, useState } from 'react'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import MaxWidth from '../../layout/MaxWidth/MaxWidth'
import css from './Cookies.module.scss'
import cx from 'classnames'
import AnimateHeight from 'react-animate-height'
import CloseIcon from '../../../public/static/icons/close-1.svg'

const Cookies: FC = () => {
  const [accept, setAccept] = useLocalStorage('accept-cookies', false)
  const [active, setActive] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!accept) {
      setTimeout(() => setActive(true), 1000)
    }
  }, [accept])

  return (
    <div className={cx(css.wrapper, active && css.active)}>
      <button
        type="button"
        onClick={() => setActive(false)}
        className={css.close}
      >
        <CloseIcon />
      </button>
      <MaxWidth className={cx(css.container, show && css['container--show'])}>
        {!show && (
          <>
            <span className={css.text}>Мы используем сookie</span>
            <button
              type="button"
              className={css.showButton}
              onClick={() => setShow(true)}
            >
              Зачем?
            </button>
          </>
        )}
        <AnimateHeight height={show ? 'auto' : 0}>
          <p className={css.text}>
            Мы&nbsp;используем &laquo;cookie&raquo; с&nbsp;целью персонализации
            сервисов и&nbsp;повышения удобства пользования веб-сайтом. Если
            Вы&nbsp;не&nbsp;хотите, чтобы Ваши пользовательские данные
            обрабатывались, пожалуйста, ограничьте их&nbsp;использование
            в&nbsp;своём браузере
          </p>
          <button
            onClick={() => {
              setActive(false)
              setAccept(true)
            }}
            type="button"
            className={css.button}
          >
            Согласен
          </button>
        </AnimateHeight>
      </MaxWidth>
    </div>
  )
}

export default Cookies
